import { Spacer } from "~/widgets/spacer";
import "./generating.css";
export default function GiftCardGenerationInProgressComponent() {
  return (
    <div class="flex h-full flex-col items-center justify-center bg-black text-center text-white">
      <div class={"loader"}></div>
      <Spacer height={20} />
      <span class="text-h3">
        Generating
        <br /> your gift card
      </span>
    </div>
  );
}
