import { createSignal, JSX, on, onCleanup, onMount, Show } from "solid-js";
import { Meta, Title } from "@solidjs/meta";
import { giftImageWithBg } from "~/assets/assets";
import {
  BrandDetailResponse,
  VoucherDetailResponse,
} from "~/server/types/gift";
import { FAQ } from "~/data/static_faqs/types";
import { HubbleEvent, webEventManager } from "~/data/events";
import { GiftBackgroundV3 } from "../gift_background";
import { GiftFooter } from "../gift_footer";
import { GiftContainerV3 } from "./gift_v3_container";

export type GiftPropsV3 = {
  isPreview: boolean;
  showGuidelines: boolean;
  brand: BrandDetailResponse;
  voucher?: VoucherDetailResponse;
  amount: number;
  description: string;
  senderPhoneNumber?: string;
  getGiftVoucherSectionComponent?: () => JSX.Element;
  tncSubtitle?: string | undefined;
  onClickUnwrapGift?: () => void;
  onUnwrapAnimationComplete?: () => void;
  faqs?: FAQ[];
  defaultEventProps?: Record<string, any>;
};

export function GiftRootV3(props: GiftPropsV3) {
  const [showFooter, setShowFooter] = createSignal(false);
  const [isShadowVisible, setIsShadowVisible] = createSignal(false);
  const [interactedPostAnimation, setInteractedPostAnimation] =
    createSignal(false);

  onMount(() => {
    webEventManager.sendEvent(
      HubbleEvent.VISIT_GIFT_LANDING_PAGE,
      props.defaultEventProps
    );
    onCleanup(() => {
      detachEventListeners();
    });
  });

  return (
    <div id="gift-canvas">
      <div class="relative">
        <GiftBackgroundV3 shadow={isShadowVisible} />
        <div class="absolute inset-0 z-[15] flex max-h-screen flex-col items-center justify-center overflow-hidden text-white">
          <MetaTag />
          <GiftContainerV3
            amount={props.amount}
            brand={props.brand}
            description={props.description}
            onClickUnwrapGift={() => {
              props.onClickUnwrapGift?.();
              return setShowFooter(true);
            }}
            voucher={props.voucher}
            onUnwrapAnimationComplete={async () => {
              props.onUnwrapAnimationComplete?.();
              setIsShadowVisible(true);
              attachEventListeners();
              await new Promise((resolve) => setTimeout(resolve, 1500));
              if (!interactedPostAnimation()) {
                scrollDownwards();
              }
            }}
          />
        </div>
      </div>
      <Show when={showFooter()}>
        <GiftFooter
          brand={props.brand}
          showGuidelines={props.showGuidelines}
          isPreview={props.isPreview}
          senderPhoneNumber={props.senderPhoneNumber}
          tncSubtitle={props.tncSubtitle}
          faqs={props.faqs}
          defaultEventProps={props.defaultEventProps}
        />
      </Show>
    </div>
  );

  function MetaTag() {
    return (
      <>
        <Title>{`A gift for you`}</Title>
        <Meta name="title" content={`A gift for you`} />
        <Meta name="description" content={`Tap to unwrap`} />
        <Meta name="image" content={giftImageWithBg} />
      </>
    );
  }

  function scrollDownwards() {
    const footer = document.getElementById("gift-footer");
    const footerHeight = footer?.offsetHeight ?? 0;
    import("jquery")
      .then((module) => {
        return ((window as any).$ = module.default);
      })
      .then(() => {
        $("html, body").animate(
          { scrollTop: footerHeight + 50 },
          1000,
          "swing"
        );
      })
      .catch((err) => {
        alert(err);
      });
  }

  function attachEventListeners() {
    const el = document.getElementById("gift-canvas");
    el?.addEventListener("touchstart", onEvent);
    el?.addEventListener("touchend", onEvent);
    el?.addEventListener("touchcancel", onEvent);
    el?.addEventListener("touchmove", onEvent);
    window.onscroll = onEvent;
  }

  function detachEventListeners() {
    const el = document.getElementById("gift-canvas");
    el?.removeEventListener("touchstart", onEvent);
    el?.removeEventListener("touchend", onEvent);
    el?.removeEventListener("touchcancel", onEvent);
    el?.removeEventListener("touchmove", onEvent);
    window.onscroll = null;
  }

  function onEvent(event: Event) {
    setInteractedPostAnimation(true);
    detachEventListeners();
  }
}
