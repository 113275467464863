import { Spacer } from "~/widgets/spacer";
import { ButtonDark } from "../verification/components";

export default function GiftCardGenerationFailedComponent() {
  return (
    <div class="relative flex h-full w-full flex-col  items-center justify-center bg-black text-center text-white">
      <WarningCircle />
      <Spacer height={20} />
      <span class="text-h3">
        Uh! couldn’t generate
        <br />
        your gift card
      </span>
      <div class="absolute  bottom-0 flex w-full flex-col p-5">
        <span class="text-medium text-[#FFBF42]">
          No coin has been deducted from your account
        </span>
        <Spacer height={20} />
        <ButtonDark
          onClick={() => {
            window.history.back();
          }}
        >
          Close
        </ButtonDark>
      </div>
    </div>
  );
}

function WarningCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
    >
      <rect x="0.5" width="60" height="60" rx="30" fill="#7C7C7C" />
      <rect
        x="28.625"
        y="12"
        width="3.75"
        height="22.5"
        rx="1.875"
        fill="white"
      />
      <circle cx="30.5" cy="42.75" r="3" fill="white" />
    </svg>
  );
}
